const presets = {
  "none": {
    short: "",
    long: "",
    department: "alle"
  },
  "kw_lw": {
    short: "GK-Kurz / GK-Lang",
    long: "Erst GK-Kurz, dann GK-Lang.",
    department: "gk_kk"
  },
  "lw_kw": {
    short: "GK-Lang / GK-Kurz",
    long: "Erst GK-Lang, dann GK-Kurz.",
    department: "gk_kk"
  },
  "lg_lp": {
    short: "LG/LP",
    long: "LG/LP",
    cashierId: "4",
    supervisorId: "4",
    department: "lg_lp"
  },
  "vl": {
    short: "VL",
    long: "Vorderlader",
    department: "vl"
  }
}

const eventFormHelpers = () => {
  const presetDropdown = document.getElementsByClassName("event-preset-dropdown")[0]
  const departmentDropdown = document.getElementById("event_department")
  const descriptionInput = document.getElementById("event_description_long")
  const descriptionShortInput = document.getElementById("event_description_short")
  const supervisorInput = document.getElementById("event_supervisor_id")
  const cashierInput = document.getElementById("event_cashier_id")

  console.log(descriptionShortInput)

  if (presetDropdown && descriptionInput) {
    presetDropdown.addEventListener ("change", (e) => {
      const preset = e.target.value
      console.log("preset", departmentDropdown.options)

      departmentDropdown.value = presets[preset].department
      descriptionInput.value = presets[preset].long
      descriptionShortInput.value = presets[preset].short

      if (preset == "lg_lp") {
        supervisorInput.value = presets[preset].supervisorId
        cashierInput.value = presets[preset].cashierId
      }
    });
  }
}

export default eventFormHelpers
