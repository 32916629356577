// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import ("./channels")
import ("chartkick")
import ("chart.js")

// fonts
import("./styles/fonts/old_english_five.ttf")

// add js part of bootstrap (css part is added via application.scss)
import("bootstrap/dist/js/bootstrap")

// js
import registerHelpers from './form_helpers'
registerHelpers()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_tag helper in views (e.g <%= image_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../assets/images', true)
const imagePath = (name) => images(name, true)

// TODO: reenable stimulusjs
// import "./controllers"

document.addEventListener("turbo:load", function() {
  console.log("adding A2HS-Button")
  let deferredPrompt;
  const addBtn = document.querySelector(".a2hs-button");
  addBtn.style.display = "none";

  window.addEventListener("beforeinstallprompt", (e) => {
    console.log("beforeinstallprompt")
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI to notify the user they can add to home screen
    addBtn.style.display = "block";

    addBtn.addEventListener("click", (e) => {
      // hide our user interface that shows our A2HS button
      addBtn.style.display = "none";
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt = null;
      });
    });
  });
});
